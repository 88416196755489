<script setup lang="ts">
	import { SwaggerUIBundle, SwaggerUIStandalonePreset } from "swagger-ui-dist";

	definePageMeta({
		auth: false,
		layout: "empty-layout",
	});

	const swaggerDomNode = ref(null);

	onMounted(() => {
		const config = useRuntimeConfig();

		SwaggerUIBundle({
			url: `${config.public.authOrigin}/api/docs`,
			domNode: swaggerDomNode.value,
			deepLinking: true,
			presets: [SwaggerUIBundle.presets.apis, SwaggerUIStandalonePreset],
			layout: "StandaloneLayout",
		});
	});
</script>

<template>
	<div class="swagger-ui" ref="swaggerDomNode"></div>
</template>

<style lang="scss">
	@import "@/assets/styles/swagger-ui.css";
</style>
